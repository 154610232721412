
$primary: hsl(172, 67%, 45%);
$secondary: hsl(0, 0%, 100%);

$bgPage: hsl(185, 41%, 84%);

$colorTitle: hsl(182, 26%, 29%);
$colorInputText: hsl(202, 50%, 97%);

$veryDarkCyan: hsl(183, 100%, 15%);
$darkGrayishCyan: hsl(186, 14%, 43%);
$darkGrayishCyanLighther: hsl(184, 14%, 56%);
$lightGrayishCyanLighter: hsl(189, 41%, 97%);


$errorColor: rgb(139, 0, 0);


$fontSize_form: 1.5rem;
$familyFont: 'Space Mono', monospace, sans-serif;