@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;   
}

html {
    scroll-behavior: smooth;
}

body {
    margin:0;
    padding: 0;
    background: $bgPage;
    font-family: $familyFont;
}
